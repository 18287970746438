<template>
    <div>
        <p class="text-h5 pb-6 mb-0">Review Promoter Application</p>
        <v-form lazy-validation ref="form" v-model="valid">
            <v-alert type="info" class="alert" v-if="isClosed()">This application has been reviewed. You can close this page.</v-alert>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="text-h6 mb-0">Basic Info</p>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="NRIC First Name*" :rules="rules.first_name" :error-messages="errors.first_name" v-model="object.profile.first_name" v-if="!isClosed()"></v-text-field>
                    <display-label label="NRIC First Name" :text="object.profile.first_name" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="NRIC Last Name*" :rules="rules.last_name" :error-messages="errors.last_name" v-model="object.profile.last_name" v-if="!isClosed()"></v-text-field>
                    <display-label label="NRIC Last Name" :text="object.profile.last_name" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="Alias Name" :rules="rules.alias_name" :error-messages="errors.alias_name" v-model="object.profile.alias_name" v-if="!isClosed()"></v-text-field>
                    <display-label label="Alias Name" :text="object.profile.alias_name" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="NRIC*" :rules="rules.nric" :error-messages="errors.nric" v-model="object.profile.nric" v-if="!isClosed()"></v-text-field>
                    <display-label label="NRIC" :text="object.profile.nric" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="Email" :rules="rules.email" :error-messages="errors.email" v-model="object.profile.email" v-if="!isClosed()"></v-text-field>
                    <display-label label="Email" :text="object.profile.email" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Gender*" :items="genders" :rules="rules.gender" :error-messages="errors.gender" v-model="object.profile.gender" v-if="!isClosed()"></v-select>
                    <display-label label="Gender" :text="object.profile.gender" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Race*"  :items="races" :rules="rules.race" :error-messages="errors.race" v-model="object.profile.race" v-if="!isClosed()"></v-select>
                    <display-label label="Race" :text="object.profile.race" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-menu offset-y transition="scale-transition" min-width="auto"  :close-on-content-click="false" v-model="birthDateMenu" v-if="!isClosed()">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense clearable label="Date of Birth*" v-bind="attrs" v-on="on" :rules="rules.dob" :error-messages="errors.dob" v-model="object.profile.dob.value"></v-text-field>
                        </template>
                        <v-date-picker no-title color="primary" v-model="object.profile.dob.value" @input="birthDateMenu = false"></v-date-picker>
                    </v-menu>
                    <display-label label="Date of Birth" :text="object.profile.dob.value" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="Mobile Number*" :rules="rules.mobile_number" :error-messages="errors.mobile_number" v-model="object.profile.mobile_number" v-if="!isClosed()"></v-text-field>
                    <display-label label="Mobile Number" :text="object.profile.mobile_number" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Citizenship*" :items="citizenships" :rules="rules.citizenship" :error-messages="errors.citizenship" v-model="object.profile.citizenship" v-if="!isClosed()"></v-select>
                    <display-label label="Citizenship" :text="object.profile.citizenship" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-menu offset-y transition="scale-transition" min-width="auto"  :close-on-content-click="false" v-model="prDateMenu" v-if="!isClosed()">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense clearable label="Date of PR" v-bind="attrs" v-on="on" :rules="rules.dopr" :error-messages="errors.dopr" v-model="object.profile.dopr.value"></v-text-field>
                        </template>
                        <v-date-picker no-title color="primary" v-model="object.profile.dopr.value" @input="prDateMenu = false"></v-date-picker>
                    </v-menu>
                    <display-label label="Date of PR" :text="object.profile.dopr.value" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Choice of CPF*"  :items="cpf_choices" :rules="rules.cpf_choice" :error-messages="errors.cpf_choice" :menu-props="{top: false, offsetY: true}" v-model="object.profile.cpf_choice" @change="updateSDLPayable" v-if="!isClosed()"></v-select>
                    <display-label label="Choice of CPF" :text="object.profile.first_name" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Shirt Size*" :items="shirt_sizes" :rules="rules.shirt_size" :error-messages="errors.shirt_size" :menu-props="{top: false, offsetY: true}" v-model="object.profile.shirt_size" v-if="!isClosed()"></v-select>
                    <display-label label="Shirt Size" :text="object.profile.shirt_size" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="SHG Contribution*" :items="agencies" :rules="rules.agency" :error-messages="errors.agency" :menu-props="{top: false, offsetY: true}" v-model="object.profile.agency" v-if="!isClosed()"></v-select>
                    <display-label label="Agency" :text="object.profile.agency" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="F/G Type" :items="fg_types" :error-messages="errors.fg_type" v-model="object.profile.fg_type" v-if="!isClosed()"></v-select>
                    <display-label label="F/G Type" :text="object.profile.fg_type" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-checkbox dense clearable label="SDL Payable" :error-messages="errors.sdl" v-model="object.profile.sdl" @change="updateCPFChoice" v-if="!isClosed()"></v-checkbox>
                    <display-label label="SDL Payable" :text="object.profile.sdl?'Yes':'No'" v-if="isClosed()"></display-label>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="text-h6 mb-0">Address Info</p>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete dense clearable label="District*" :items="districts" :rules="rules.district" :error-messages="errors.district" v-model="object.address.district" v-if="!isClosed()"></v-autocomplete>
                    <display-label label="District" :text="object.address.district" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field dense clearable label="Block No." :error-messages="errors.block_number" v-model="object.address.block_number" v-if="!isClosed()"></v-text-field>
                    <display-label label="Block No." :text="object.address.block_number" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="Street Name*" :rules="rules.street_name" :error-messages="errors.street_name" v-model="object.address.street_name" v-if="!isClosed()"></v-text-field>
                    <display-label label="Street Name" :text="object.address.street_name" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field dense clearable label="Unit No." :error-messages="errors.unit_number" v-model="object.address.unit_number" v-if="!isClosed()"></v-text-field>
                    <display-label label="Unit No." :text="object.address.unit_number" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field dense clearable label="Building Name" :error-messages="errors.building_name" v-model="object.address.building_name" v-if="!isClosed()"></v-text-field>
                    <display-label label="Building Name" :text="object.address.building_name" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field dense clearable label="Postal Code*" :rules="rules.postal_code" :error-messages="errors.postal_code" v-model="object.address.postal_code" v-if="!isClosed()"></v-text-field>
                    <display-label label="Postal Code" :text="object.address.postal_code" v-if="isClosed()"></display-label>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="text-h6 mb-0">Bank Info</p>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete dense clearable label="Bank*" :items="banks" :rules="rules.bank" :error-messages="errors.bank" v-model="object.bank.bank" v-if="!isClosed()"></v-autocomplete>
                    <display-label label="Bank" :text="object.bank.bank" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field dense clearable label="Branch Code*" :disabled="isDisabledBranch" :rules="rules.branch_code" :error-messages="errors.branch_code" v-model="object.bank.branch_code" v-if="!isClosed()"></v-text-field>
                    <display-label label="Branch Code" :text="object.bank.branch_code" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field dense clearable label="Account Number*" :rules="rules.account_number" :error-messages="errors.account_number" v-model="object.bank.account_number" v-if="!isClosed()"></v-text-field>
                    <display-label label="Account Number" :text="object.bank.account_number" v-if="isClosed()"></display-label>
               </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="text-h6 mb-0">Interview Result</p>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea dense clearable label="Comment*" class="no-resize" rows="5" :rules="rules.comment" :error-messages="errors.comment" v-model="object.application.comment" v-if="!isClosed()"></v-textarea>
                    <display-label label="Comment" :text="object.application.comment" v-if="isClosed()"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="isClosed()">
                    <display-label label="Status" :text="object.application.status"></display-label>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="!isClosed()">
                    <small class="red--text">* Required field</small><br>
                    <small class="red--text">Note: Date of PR is required if the citizenship is PR.</small>
                </v-col>
            </v-row>
        </v-form>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                <v-btn class="mr-2 white--text elevation-0" color="red" :loading="loading" :ripple="false" @click="save('r')" v-if="!object.application.is_closed">Reject</v-btn>
                <v-btn class="mr-2 elevation-0" color="primary" :ripple="false" :loading="loading" @click="save('a')" v-if="!object.application.is_closed">Approve</v-btn>
            </v-col>
        </v-row>
        <message-notifier ref="notifier"></message-notifier>
        <application-confirmation-dialog ref="confirmDialog" @updated="updateObject"></application-confirmation-dialog>
    </div>
</template>


<script>

import { encodeBase64, decodeBase64 } from '@/utils/encodes'
import MessageNotifier from '../../../shared/MessageNotifier.vue'
import DisplayLabel from '../../../shared/DisplayLabel.vue'
import PromoterApplicationConfirmationDialog from './PromoterApplicationConfirmationDialog.vue'

export default {
    name: 'PromoterApplicationForm',
    components: {
        messageNotifier: MessageNotifier,
        displayLabel: DisplayLabel,
        applicationConfirmationDialog: PromoterApplicationConfirmationDialog,
    },
    data() {
		return {
            valid: true,
            loading: false,
            birthDateMenu: false,
			prDateMenu: false,
			isDisabledBranch: true,
            races: [],
            genders: [],
			citizenships: [],
			districts: [],
			banks: [],
			agencies: [],
			cpf_choices: [],
            shirt_sizes: [],
            fg_types: [],
            object: {
                profile: {
                    dob: {},
                    dopr: {}
                },
                bank: {},
                address: {},
                application: {}
            },
			rules: {
				first_name: [
					(value) => !!value || 'First name is required',
					(value) => value && value.length >= 1 && value.length <= 100 || 'First name must be between 1 and 100 characters'
				],
				last_name: [
					(value) => !!value || 'Last name is required',
					(value) => value && value.length >= 1 && value.length <= 100 || 'Last name must be between 1 and 100 characters'
				],
				alias_name: [
                    (value) => {
                        if(value && value.length >= 2 && value.length <= 100) {
                            return true
                        }else if(value === ""){
                            return true
                        }
                        return 'Alias name must between 3 and 100 characters'
                    }
				],
				nric: [
                    (value) => !!value || 'NRIC is required',
					(value) => value && value.length == 9 || 'NRIC must be 9 characters'
				],
				email: [
					(value) => {
                        if(value && !/.+@.+\..+/.test(value)){
                            return 'Email is invalid'
                        }

                        return true
                    }
				],
                race: [
                    (value) => !!value || 'Race is required'
                ],
                gender: [
                    (value) => !!value || 'Gender is required'
                ],
                agency: [
                    (value) => !!value || 'Agency is required'
                ],
				citizenship: [
					(value) => !!value || 'Citizenship is required'
				],
				dob: [
					(value) => !!value || 'Date of birth is required'
				],
				mobile_number: [
                    (value) => {
                        if(!value) {
                            return 'Mobile number is required'
                        }

                        if(value.length !== 8) {
                            return 'Postal code must be 8 digits'
                        }

                        if(!/^\d+$/.test(value)) {
                            return 'Mobile number must be 8 digits'
                        }

                        if(!['8', '9'].includes(value.substring(0, 1))) {
                            return 'Mobile number must starts with 8 or 9'
                        }

                        return true
					}
				],
				dopr: [
                    (value) => {
                        const citizenship = this.object.profile.citizenship
                        if(!value && citizenship && citizenship.toLowerCase() === 'pr'){
                            return "Date of PR is required"
                        }

                        return true
                    }
				],
                cpf_choice: [
                    (value) => !!value || "CPF Choice is required"
                ],
                shirt_size: [
                    (value) => !!value || "Shift size is required"
                ],
                district: [
                    (value) => !!value || 'District is required'
                ],
                street_name: [
                    (value) => !!value || 'Street name is required'
                ],
                postal_code: [
                    (value) => {
                        if(!value) {
                            return 'Postal code is required'
                        }

                        if(value.length !== 6) {
                            return 'Postal code must be 6 digits'
                        }

                        if(!/^\d+$/.test(value)) {
                            return 'Mobile number must be 6 digits'
                        }

                        return true
                    }
                ],
                bank: [
                    (value) => !!value || 'Bank is required'
                ],
                branch_code: [
                    (value) => {
                        if(this.object.bank.bank){
                            const bank = this.banks.filter(item => item.value === this.object.bank.bank)[0]

                            if(["DBS", "POSB", "OCBC", "UOB"].indexOf(bank.code) > -1){
                                this.isDisabledBranch = true
                                return true
                            }else{
                                this.isDisabledBranch = false
                            }
                        }

                        if(!value) {
                            return 'Branch code is required'
                        }

                        if(value.length < 3 || value.length > 10){
                            return 'Branch code must be between 3 and 10 characters'
                        }

                        if(!/^\d+{3, 10}$/.test(value)) {
                            return 'Branch code must be numeric value'
                        }

                        return true
                    }
                ],
                account_number: [
                    (value) => {
                        if(!value) {
                            return "Account number is required"
                        }

                        if(this.object.bank.bank) {
                            var bank = this.banks.filter(item => item.value === this.object.bank.bank)[0]
                            if(bank.account_digits.indexOf(value.length.toString()) === -1){
                                return 'Account number must ' + bank.account_digits.join(' or ') + ' characters'
                            }
                        }

                        if(!/^\d+$/.test(value)){
                            return 'Account number must be numeric value'
                        }

                        return true
                    }
                ],
                comment: [
                    (value) => !!value || 'Comment is required'
                ]
			},
			errors: {}
		}
	},
	computed: {
		form() {
			return this.$refs.form
		},
		notifier() {
            return this.$refs.notifier
        },
        confirmDialog() {
            return this.$refs.confirmDialog
        }
	},
	mounted: function() {
        this.get()
	},
	methods: {
        get: function() {
			this.$store.dispatch('epanel/dashboard/getApplication', { id: this.$route.params.id }).then((response) => {
				this.citizenships = response.data.citizenships
				this.races = response.data.races
				this.genders = response.data.genders
				this.districts = response.data.districts
				this.banks = response.data.banks
				this.agencies = response.data.agencies
				this.cpf_choices = response.data.cpf_choices
				this.shirt_sizes = response.data.shirt_sizes
				this.fg_types = response.data.fg_types
				this.object = response.data.object
				this.updateBank()
			}).catch((error) => {
                this.errors = error.errors
			})
		},
		save: function(action) {
            if(this.form.validate()){
                var object = { 'first_name': this.object.profile.first_name, 'last_name': this.object.profile.last_name,
                               'alias_name': this.object.profile.alias_name, 'nric': encodeBase64(this.object.profile.nric),
                               'email': this.object.profile.email, 'gender': this.object.profile.gender,
                               'race': this.object.profile.race, 'dob': this.object.profile.dob.value,
                               'mobile_number': this.object.profile.mobile_number, 'citizenship': this.object.profile.citizenship,
                               'dopr': this.object.profile.dopr.value, 'cpf_choice': this.object.profile.cpf_choice,
                               'shirt_size': this.object.profile.shirt_size, 'district': this.object.address.district,
                               'block_number': this.object.address.block_number, 'street_name': this.object.address.street_name,
                               'unit_number': this.object.address.unit_number, 'postal_code': this.object.address.postal_code,
                               'building_name': this.object.address.building_name, 'bank': this.object.bank.bank,
                               'branch_code': encodeBase64(this.object.bank.branch_code), 'action': action, 'agency': this.object.profile.agency,
                               'account_number': encodeBase64(this.object.bank.account_number), 'comment': this.object.application.comment,
                               'fg_type': this.object.profile.fg_type, 'sdl': this.object.profile.sdl }
                this.confirmDialog.updateObject(object)
                this.confirmDialog.open()
            }
		},
		openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        updateBank: function() {
            this.object.bank.branch_code = decodeBase64(this.object.bank.branch_code)
            this.object.bank.account_number = decodeBase64(this.object.bank.account_number)
        },
        updateObject: function(item) {
            this.$set(this, 'object', item.object)
            this.updateBank()
            this.openNotifier(item.message)
        },
        updateSDLPayable: function() {
            if(this.object.profile.cpf_choice === 'Y'){
                this.object.profile.sdl = true
            }else if(this.object.profile.cpf_choice === 'N') {
                this.object.profile.sdl = false
            }
        },
        updateCPFChoice: function() {
            if(this.object.profile.sdl){
                this.object.profile.cpf_choice = 'Y'
            }else{
                this.object.profile.cpf_choice = 'N'
            }
        },
        isClosed: function() {
            return this.object.application.is_closed
        }
	}
}

</script>