<template>
	<v-dialog persistent max-width="764px" ref="statusDialog" v-model="dialog">
		<v-card>
			<v-card-title class="pl-4 pr-4">
				<span class="text-h5 mb-3" v-text="title"></span>
			</v-card-title>
			<v-card-text class="pl-4 pr-4">
				<v-row>
					<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
						<v-alert dense border="left" type="warning" v-html="text"></v-alert>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions class="pl-4 pr-4">
				<v-spacer></v-spacer>
				<v-btn text @click="close">Let me think about it</v-btn>
				<v-btn text color="primary" :loading="loading" @click="save">Yes, do it</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>


<script>

export default {
	name: 'PromoterApplicationConfirmationDialog',
	data() {
		return {
			dialog: false,
			loading: false,
			title: '',
			text: '',
			object: {
				action: ''
			},
			errors: {}
		}
	},
	computed: {
		statusDialog() {
			return this.$refs.statusDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
			this.updateContent()
		},
		close: function() {
			this.dialog = false
			this.loading = false
		},
        updateObject: function(item) {
            this.object = item
        },
		save: function() {
            this.loading = true
			this.$store.dispatch('epanel/dashboard/updateApplication', { id: this.$route.params.id, object: this.object }).then((response) => {
                this.loading = false
                this.$emit('updated', { object: response.data.object, message: response.message })
                this.close()
            }).catch((errors) => {
                this.errors = errors.data?errors.data.errors:errors
                this.loading = false
            })
		},
		updateContent: function() {
            if(this.object.action === 'a') {
				this.title = 'Approve Application'
				this.text = 'Are you sure that you want to approve this application?'
			}else if(this.object.action === 'r') {
				this.title = 'Reject Application'
				this.text = 'Are you sure that you want to reject this application?'
			}
		}
	}
}

</script>